import "./App.scss";
import { AppRoutes } from "./routes";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className='app'>
          <AppRoutes />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
